import { useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import ImageUploader from '../../shared/ImageUploader';
import VideoUploader from '../../shared/VideoUploader';
import http from "../../AxoisClient";


const ExerciseEdit = () => {
    const [isBusy, setIsBusy] = useState(false);
    const [data, setData] = useState({
        id: 0,
        name: '',
        thumbnailUri: '',
        category:'Handel',
        videoUri: '',
        description: ''
    });


    const {id} = useParams();
    useEffect(() => {


        const fetchData = () => {
            http.get(`/api/admin/exercises/${id}`)
                .then(res => res.data)
                .then(d => {
                    setData(d);
                
                })
                .catch(e => alert(e.messages));
        };
    
    
        if (id !== undefined) {
            fetchData();
        }
    }, [id]);



    
    const postExerciseAsync = async () => {
        setIsBusy(true);
        http.post('/api/admin/exercises', data)
            .then(s => window.location.reload())
            .catch(e => alert(e.messages))
            .finally(() => setIsBusy(false));
    };


    return <div className='container pb-5'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to='/'>Hem</NavLink></li>
                <li className="breadcrumb-item"><NavLink to='/exercises'>Övningar</NavLink></li>
                <li className="breadcrumb-item active"
                    aria-current="page">{id === undefined ? 'Skapa' : 'Redigera'}</li>
            </ol>
        </nav>

        <div className="d-flex mb-4 pb-0">
            <h4 className="flex-fill">{id === undefined ? 'Skapa övning' : 'Redigera artikel'}</h4>
            <button className="btn btn-primary" disabled={isBusy} onClick={postExerciseAsync}>{isBusy ? 'Sparar' :'Spara'}</button>
        </div>

        <div className='form-floating my-3'>
            <input type={"text"} className="form-control" name="title" value={data.name}
                   onChange={(e) => setData({...data, name: e.target.value})}/>
            <label htmlFor="title">Namn</label>
        </div>

        <div className='form-floating my-3'>
            <select value={data.category} name="category" className="form-control"  onChange={(e) => setData({...data, category: e.target.value})}>
                <option>Hantel</option>
                <option>D-ball</option>
                <option>Kettlebell</option>
                <option>Kroppsvikt</option>
                <option>Skivstång</option>
                <option>Släde</option>
            </select>
            
            <label htmlFor="category">Kategori</label>
        </div>


        {
            id !== undefined && 
        <div className="form-box my-3">
            <label htmlFor="steps">Thumbnail (500x500) *.jpg</label>
            <ImageUploader src={data.imageUri} onChange={(e) => setData({...data, ImageUri: e})}/>
        </div>
}

        <div className="form-box my-3">
            <label htmlFor="steps">Video (max 15mb) *.mp4</label>
            <VideoUploader src={data.videoUri} onChange={(e) => setData({...data, videoUri: e})}/>
        </div>
    </div>;
}

export default ExerciseEdit;