import {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Container, Table } from "reactstrap";
import http from "../../AxoisClient";

const ProgramList = () => {
    const navigate = useNavigate();
    const [data, setData] = useState(null);

    useEffect(() => {
        http.get('/api/admin/workout?_order=DESC')
            .then(d=> setData(d.data));

    }, []);
    

    return <Container>
                <Breadcrumb listTag="div">
                    <BreadcrumbItem href="/" tag="a">Hem</BreadcrumbItem>
                    <BreadcrumbItem href="/programs" tag="a">Program</BreadcrumbItem>
                </Breadcrumb>

                <div className="row">
                    <div className="col"><h4>Program</h4></div>
                    <div className="col d-flex justify-content-end">
                        <a href="/programs/new" className="btn btn-primary">Skapa nytt</a>
                    </div>
                </div>

                <Table>
                    <tbody>
                        {data && data.data.map((w, i) => (
                        <tr onClick={() => navigate('/programs/' + w.id)}>
                            <td>{w.id}</td>
                            <td>{w.week}</td>
                            <td>{w.title}</td>
                        </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>;
}

export default ProgramList;