import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import  ProgramList  from "./components/programs/list";

import ProgramEdit from './components/programs/edit';
import ExerciseEdit from './components/exercises/edit';
import ExerciseList from './components/exercises/list';
import UserList from './components/users/list';
import UserEdit from './components/users/edit';
import Home from './components/dashboard/Home';

const AppRoutes = [
  {
    index: true,
    showInMenu: true,
    path:'/',
    title: 'Hem',
    element: <Home />
  },
  {
    path: '/programs',
    showInMenu: true,
    title: 'Program',
    element: <ProgramList />
  },
  {
    path: '/programs/:id',
    element: <ProgramEdit />
  },
  {
    path: '/exercises',
    showInMenu: true,
    title: 'Övningar',
    element: <ExerciseList />
  },
  {
    path: '/exercises/edit/:id',
     element: <ExerciseEdit/>,
  },
    {
    path: '/exercises/new',
     element: <ExerciseEdit/>,
  },
  {
    path: '/users',
    showInMenu: true,
    title: 'Användare',
    element: <UserList />
  },
  {
    path: '/users/:id',
    element: <UserEdit />
  },
  ...ApiAuthorzationRoutes
];

export default AppRoutes;
