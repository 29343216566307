import React, {  useEffect, useState } from 'react';
import http from "../../AxoisClient";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


const Home = () => {
    const [isBusy, setIsBusy] = useState(true);
    const [subs, setSubs] = useState();

    useEffect(() => {


        const fetchData = () => {
          setIsBusy(true);
            http.get(`/api/admin/dashboard`)
                .then(res => res.data)
                .then(d => {
                    setSubs(d.memberCount);
                })
                .catch(e => alert(e.messages))
                .finally(() => setIsBusy(false));
        };
        fetchData();
    }, []);

    return   <div>
    <h1>Välkommen till OT Portal!</h1>
    <h6>Antal prenumerationer: {isBusy ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : subs}</h6>
     </div>
};

export default Home;