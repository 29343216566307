import axios from "axios";
import authService from "./components/api-authorization/AuthorizeService";

const http = axios.create();

http.interceptors.request.use(async (req) => {
    const token = await authService.getAccessToken();
    if (token) {
        req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
})

http.interceptors.response.use(response => {
    return response;
}, error => {

    if (error.response.status === 403) {
        window.location = '/403';
    } else if (error.response.status === 401) {
        authService.signOut({returnUrl: window.location.href})
    } else if (error.isAxiosError) {
        return Promise.reject(error.response.data);
    } else {
        if (typeof error.response.data === 'string') {
            alert(error.response.data);
        } else if (error.response.data.errors) {
            alert(JSON.stringify(error.response.data.errors));
        }

        return Promise.reject(error)
    }
});


export default http;