import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignOut} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';


const Avatar = styled.img`
    width: 25px;
  `;

export class LoginMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      userName: null
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
    this.setState({
      isAuthenticated,
      userName: user && user.fullName,
      avatarUri: user&& user.avatarUri
    });
  }

  render() {
    const { isAuthenticated, userName, avatarUri } = this.state;
    if (isAuthenticated) {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = `${ApplicationPaths.LogOut}`;
      const logoutState = { local: true };
      return this.authenticatedView(userName, avatarUri, profilePath, logoutPath, logoutState);
    }
  }

  authenticatedView(userName, avatarUri, profilePath, logoutPath, logoutState) {
    return (<Fragment>
      <NavItem>
        <NavLink tag={Link} className="text-white" to='#'>
            <Avatar src={avatarUri} className="rounded-circle"/>
           {userName}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink replace tag={Link} className="text-white" to={logoutPath} state={logoutState}>
          <FontAwesomeIcon icon={faSignOut}/>
        </NavLink>
      </NavItem>
    </Fragment>);
  }


}
