import React, { Component } from 'react';
import './LayoutLogin.scss';


export class LayoutLogin extends Component {
  static displayName = LayoutLogin.name;

  render() {
    return <div className='login-page'>
              <div class="bg"></div>
              <div class="container">
                  <main role="main">
                      <div class="auth-form-container">
                      <div class="auth-form">
                          <div class="auth-form-content">
                              <div class="auth-form-logo mb-5">
                                  <img src="/images/ot_logo.jpg" alt="logo" />    
                              </div>
                              {this.props.children}
                          </div>
                      </div>
                      </div>
                  </main>
              </div>
              <footer class="footer text-dark sticky">
                  <div class="container text-center">
                 
                  </div>
              </footer>
      
              </div>;
  }
}
