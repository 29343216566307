
 const quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
    ],
  };

 const quillFormats = [ 'header',
    'bold', 'italic', 'underline',
    'list', 'bullet', 'indent',
  ];

  
export { quillModules, quillFormats} ;