import {useEffect, useState} from "react";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import moment from "moment";
import http from "../../AxoisClient";


const UserEdit = () => {
    //const [isBusy, setIsBusy] = useState(true);
    const [user, setUser] = useState({
        id: 0,
        userName: '',
        name: ''
    });

    const [subscription, setSubscription] = useState({
        id: 0,
        status: 0,
        purchaseDate: undefined,
        expireDate: undefined,
        autoRenewing: undefined,
        cancellationReason: ''
    });


    const {id} = useParams();
    const navigate = useNavigate();
    

    useEffect(() => {

        const fetchData = () => {

            http.get(`/api/admin/users/${id}`)
                .then(res => res.data)
                .then(d => {
                    setUser(d);
                })
                .catch(e => alert(e.messages));
    
            http.get(`api/admin/subscriptions/${id}`)
                .then(res => res.data)
                .then(d => {
                    setSubscription(d);
                })
                .catch(e => alert(e.messages));
        };
    
        if (id !== undefined) {
            fetchData();
        }

    }, [id]);

    const postArticleAsync = async () => {
        http.post('/api/admin/users', user)
            .then(s => navigate.goBack())
            .catch(e => alert(e.messages));
    };

    const deleteUserAsync = async () => {
        if(!window.confirm("Är du säker på att du vill ta bort användaren?")) return;
        
        http.delete(`/api/admin/users/${user.id}`)
            .then(s => navigate.goBack())
            .catch(e => alert(e.messages));
    };

    const getSubscriptionType = (type) => {
        switch (type) {
            case 0:
                return 'Manuell';
            case 1:
                return 'Presentkort';
            case 3:
                return 'Google';
            case 2:
                return 'Apple';
            default:
                return ' - ';
        }
    };

    const capitalizeFirstLetter = (string) => {
        if (!string) return string;

        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    return <div className='container pb-5'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to='/'>Hem</NavLink></li>
                <li className="breadcrumb-item"><NavLink to='/questions'>Användare</NavLink></li>
                <li className="breadcrumb-item active"
                    aria-current="page">{id === undefined ? 'Skapa' : 'Redigera'}</li>
            </ol>
        </nav>

        <div className="d-flex mb-4 pb-0">
            <h4 className="flex-fill">{id === undefined ? 'Skapa' : 'Redigera användare'}</h4>
            <button className="btn btn-primary" onClick={postArticleAsync}>Spara</button>
        </div>


        <div className='form-floating my-3'>
            <input type={"text"} className="form-control" name="username" value={user.userName}/>
            <label htmlFor="username">E-mail</label>
        </div>
        <div className='form-floating my-3'>
            <input type={"text"} className="form-control" name="name" value={user.name}/>
            <label htmlFor="name">Namn</label>
        </div>

        <div className="form-box my-3">
            <label>Prenumeration</label>

            {!subscription && <p className="text-muted fst-italic mt-2">Användaren har ingen aktiv prenumeration</p>}
            {subscription &&
                <div className="subscription-info row  mt-2">
                    <div className="col">
                        <p className="fw-bold">Typ</p>
                        <p className="fw-bold">Status</p>
                        <p className="fw-bold">Köpt</p>
                        <p className="fw-bold">Går ut</p>
                        <p className="fw-bold">Förnya automatiskt</p>
                    </div>
                    <div className="col">
                        <p>{getSubscriptionType(subscription.type)}</p>
                        <p>{capitalizeFirstLetter(subscription.status)} {subscription.cancellationReason?.length > 2 ? ' - ' + subscription.cancellationReason : ''}</p>
                        <p>{moment(subscription.purchaseDate).format('yyyy-MM-DD HH:mm')}</p>
                        <p>{moment(subscription.expireDate).format('yyyy-MM-DD HH:mm')}</p>
                        <p>{subscription.autoRenewing ? 'Ja' : 'Nej'}</p>
                    </div>
                </div>
            }
        </div>
        
        <div>
            <button class="btn text-danger" onClick={deleteUserAsync}>Ta bort användare</button>
        </div>
    </div>;
}

export default UserEdit;