import {Fragment, useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import styled, {css} from "styled-components";
import {useNavigate} from "react-router-dom";
import useIntersectionObserver from "./useIntersectionObserver";
import mp from "../assets/mp.webp";
import http from "../AxoisClient";

const Avatar = styled.div`
        min-height:56px;
        padding: 2px 5px;
        display: inline-flex;
        cursor: pointer;
        transition: background-color: 0.5s; 
        &:hover {
            background-color: rgba(0,0,0,0.1);
        }
    `;

const AvatarImage = styled.img`
        width: 50px;
        height: 50px;
        border-radius: 25px;
        border: 3px solid red;
        ${props => props.active && css`
            border: 3px solid lightgreen;
          `}
    `;

const LazyUserAvatar = ({userId, forceUser}) => {
    const section = useRef(null);
    const isSectionVisible = useIntersectionObserver(section);

    return (<div ref={section}>
        {isSectionVisible &&
            <UserAvatar userId={userId} forceUser={forceUser} />
        }
    </div>);
}

const UserAvatar = ({userId, forceUser}) => {
    const [isBusy, setIsBusy] = useState(true);
    const [user, setUser] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if(forceUser !== undefined){
            setUser(forceUser);
            setIsBusy(false);
            return;
        }
        http.get(`/api/admin/users/${userId}`)
            .then(res => res.data)
            .then(d => {
                setUser(d);
            
            })
            .catch(e => {
                if(e.status === 404){
                    setUser({
                        name: 'Raderad',
                        userName: 'Användaren har tagit bort sitt konto',
                        imageUri: null
                    });
                }
            })
            .finally(e=> setIsBusy(false));
            
    }, [forceUser, userId]);

  

    return <Avatar onClick={() => navigate.push(`/user/edit/${user.id}`)}>
        {!isBusy &&
            <Fragment>
            <div className="col">
                <div className="d-inline-block">
                    <AvatarImage active={user?.subscription === 'active'} alt="" src={user?.imageUri || mp}/>
                </div>
            </div>
            <div className="col flex-fill">
            <div className="ms-2">
        {user?.name}
            </div>
            <div className="ms-2 text-secondary small">
        {user?.userName}
            </div>
            </div>
            </Fragment>
        }
        { isBusy &&
            <Fragment>
            <div className="col">
                <div className="d-inline-block">
                   <FontAwesomeIcon icon={faSpinner} spin={true}/>
                </div>
            </div>
            <div className="col">
            <div className="ms-2">
                Laddar...
            </div>
            <div className="ms-2 text-secondary small">
            </div>
            </div>
            </Fragment>
        }
            </Avatar>;
}

export default LazyUserAvatar;