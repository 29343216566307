import { useEffect, useRef, useState} from "react";
import humanizeDuration from "humanize-duration";
import './VideoUploader.scss';
import http from "../AxoisClient";


const VideoUploader = (props) => {
    const [selectedFile, setSelectedFile] = useState();
    const [src, setSrc] = useState(props.src);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [uploadStarted, setUploadStarted] = useState(new Date());
    const [uploadTimeLeft, setUploadTimeLeft] = useState(0);
    const fileInput = useRef();
    const video = useRef();

    // On file upload (click the upload button)
    const onFileUpload = event => {
        setSelectedFile(event.target.files[0]);

    };

    const loadVideo = file => new Promise((resolve, reject) => {
        try {
            let video = document.createElement('video')
            video.preload = 'metadata'

            video.onloadedmetadata = function () {
                resolve(this)
            }

            video.onerror = function () {
                reject("Invalid video. Please select a video file.")
            }

            video.src = file;
        } catch (e) {
            reject(e)
        }
    })



    useEffect(() => {
        const updateParent = (s, t) => {
            props.onChange(s, t);
        };

        setSrc(props.src);
        if (selectedFile) {
            // Create an object of formData
            const formData = new FormData();
            setUploadStarted(new Date());
            // Update the formData object
            formData.append(
                "file",
                selectedFile,
                selectedFile.name
            );

            // Details of the uploaded file
            console.log(selectedFile);

            // Upload percentage
            const config = {
                onUploadProgress: progressEvent => {
                    setUploadPercentage(Math.round((progressEvent.loaded * 100) / progressEvent.total))

                    const timeElapsed = (new Date()) - uploadStarted; // Assuming that timeStarted is a Date Object
                    const uploadSpeed = progressEvent.loaded / (timeElapsed / 1000); // Upload speed in second

                    // `callback` is the function that shows the time to user.
                    // The only argument is the number of remaining seconds.

                    setUploadTimeLeft(Math.round((progressEvent.total - progressEvent.loaded) / uploadSpeed) * 1000);
                }
            }

            // Request made to the backend api
            // Send formData object
            http.post("api/admin/video/upload", formData, config)
                .then(res => res.data)
                .then(s => {
                    setSrc(s.uri)
                    setSelectedFile(null);

                    loadVideo(s.uri)
                        .then(e => {
                            updateParent(s.uri, Math.round(e.duration / 60));
                        })

                    setUploadPercentage(0)

                })
                .catch(e => alert(e));
        }
         // eslint-disable-next-line
    }, [selectedFile, props.src])
    const renderImage = () => {
        if (src) {
            return <video style={{'objectFit': 'contain'}} ref={video} src={src} alt="header" autoPlay={true} loop={true} controls={true} />;
        } else {
            return <h6>Klicka här för att välja fil</h6>;

        }
    };

    return (
        <div className="video-uploader">
            <div className="video-wrapper" onClick={() => fileInput.current.click()} accept="video/mp4">
                {renderImage()}
            </div>
            <input ref={fileInput} type="file" onChange={onFileUpload}/>

            {uploadPercentage > 0 &&
                <div>
                    <progress max="100" value={uploadPercentage}>{Math.round(uploadPercentage)}%</progress>
                    <div className="text-center"><em className="small text-muted">Beräknad tid
                        kvar: {humanizeDuration(uploadTimeLeft, {language: "sv"})}</em></div>
                </div>
            }
        </div>);
};
export default VideoUploader;