import {useEffect, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import http from '../../AxoisClient';

const ExerciseList = () => {
    const [isBusy, setIsBusy] = useState(true);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');

    const navigate = useNavigate();
    useEffect(() => {
        async function fetchData() {
            setIsBusy(true);

            http.get('api/admin/exercises')
                .then(res => res.data)
                .then(setData)
                .catch(e => alert(e))
                .finally(() =>
                    setIsBusy(false));


        }

        fetchData();
    }, []);

    return <div className='container'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to='/'>Home</NavLink></li>
                <li className="breadcrumb-item active">Övningar</li>
            </ol>
        </nav>

        <div className="d-flex mb-4">
            <h4 className="flex-fill">Övningar</h4>
            <button className="btn btn-primary" onClick={() => navigate('/exercises/new')}>Skapa ny</button>
        </div>
        <div className='form-floating'>
            <input type="text" className="form-control" name="search" onChange={(e) => setSearch(e.target.value)}/>
            <label htmlFor="search">Sök övning</label>
        </div>

        {isBusy && <em>Loading</em>}

        <table className="table table-striped table-hover small">
            <thead>
            <tr>
                <th>
                    #
                </th>
                <th>
                    Kategori
                </th>
                <th>
                    Övning
                </th>
                <th>
                    Skalning
                </th>

            </tr>
            </thead>
            <tbody>
            {
                data && data.filter(a=>a.name.toLowerCase().indexOf(search) > -1).map((a, i) =>
                    <tr key={a.id} onClick={() =>  navigate(`/exercises/edit/${a.id}`)}>
                        <td>
                            #{a.id}
                        </td>
                        <td>
                            {a.category}
                        </td>
                        <td>
                            {a.name}
                        </td>
                        <td>
                            {a.scaling}
                        </td>

                    </tr>
                )
            }
            {
                data && data.length === 0 && <tr>
                    <td colSpan="4" className="text-center"><em>Inga övningar tillgängliga</em></td>
                </tr>

            }
            </tbody>
        </table>
    </div>;
}

export default ExerciseList;