import {useCallback, useEffect, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import './user.scss';
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import http from '../../AxoisClient';
import UserAvatar from '../../shared/user-avatar';

const MyPaginate = styled(ReactPaginate).attrs({
    // You can redifine classes here, if you want.
    activeClassName: 'active', // default to "disabled"
})`
      margin-bottom: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      list-style-type: none;
      padding: 0 5rem;
      li a {
        border-radius: 7px;
        padding: 0.1rem 1rem;
        margin: .2rem;
        border: gray 1px solid;
        cursor: pointer;
      }
      li.previous a,
      li.next a,
      li.break a {
        border-color: transparent;
      }
      li.active a {
        background-color: #B2BFB6;
        border-color: transparent;
        color: white;
        min-width: 32px;
      }
      li.disabled a {
        color: grey;
      }
      li.disable,
      li.disabled a {
        cursor: default;
      }
    `;

const UserList = () => {
    const [isBusy, setIsBusy] = useState(true);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('all');
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const navigate = useNavigate();
    
    
    const fetchData = useCallback(async () => {
        setIsBusy(true);

        http.get(`api/admin/users?filter=${search}&status=${filter}&page=${currentPage}`)
            .then(res => res.data)
            .then(d => {
                setData(d.data);
                setCurrentPage(d.pageNumber);
                setTotalPages(d.totalPages);
            })

            .catch(e => alert(e))
            .finally(() =>
                setIsBusy(false));

    }, [currentPage, filter,search]);

    useEffect(() => {
        fetchData();
    }, [filter, currentPage, fetchData]);


   
    
    return <div className='container'>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to='/'>Home</NavLink></li>
                <li className="breadcrumb-item active">Användare</li>
            </ol>
        </nav>
        <div className="d-flex mb-4">
            <h4 className="flex-fill">Användare</h4>
        </div>
        <form onSubmit={async (e) => { e.preventDefault(); await fetchData() }}>
        <div className='form-box'>
            <div className="input-group">
                <input type={"search"} className="form-control " name="search" onChange={e=>setSearch(e.target.value)}/>
                <div className="input-group-text">
                    <select className="form-select form-select-sm mt-0" value={filter} onChange={e => setFilter(e.target.value)}>
                        <option value='all'>Alla</option>
                        <option value='active'>Aktiva</option>
                        <option value='inactive'>Inaktiva</option>
                    </select>
                </div>
            </div>
            <label htmlFor="search">Sök användare</label>
        </div>
        </form>

        {isBusy && <em>Loading</em>}

        <table className="table table-striped table-hover small">
            <thead>
            <tr>
                <th className="w-25">
                    Namn
                </th>
                <th>
                    Nivå
                </th>
                <th>
                    Referens
                </th>
                <th>
                    Utrustning
                </th>
                <th>
                    Fokus
                </th>
                <th>
                    Tid
                </th>
            </tr>
            </thead>
            <tbody>
            {
                data && data.map((a, i) =>
                    <tr key={a.id} onClick={() => navigate.push(`/user/edit/${a.id}`)}>
                        <td>
                            <UserAvatar forceUser={a}/>
                        </td>
                        <td>
                            {a.level}
                        </td>
                        <td className="flex-wrap">
                            {a.references && a.references.split(';').map((t, j) =>
                                <div key={j} className="badge bg-primary rounded-pill mx-1">{t}</div>
                            )}
                        </td>
                        <td className="flex-wrap">
                            {a.workoutEquipment && a.workoutEquipment.split(';').map((t, j) =>
                                <div key={j} className="badge bg-primary rounded-pill mx-1">{t}</div>
                            )}
                        </td>
                        <td>
                            {a.workoutFocus && a.workoutFocus.split(';').map((t, j) =>
                                <div key={j} className="badge bg-primary rounded-pill mx-1">{t}</div>
                            )}
                        </td>
                        <td>
                            {a.workoutTimeRange && a.workoutTimeRange.split(';').map((t, j) =>
                                <div key={j} className="badge bg-primary rounded-pill mx-1">{t}</div>
                            )}
                        </td>
                    </tr>
                )
            }
            {
                data && data.length === 0 && <tr>
                    <td colSpan="4" className="text-center"><em>Inga artiklar tillgängliga</em></td>
                </tr>

            }
            </tbody>
        </table>
        <MyPaginate
            pageCount={totalPages}
            onPageChange={(e) => setCurrentPage(e.selected)}
        />
    </div>;
}

export default UserList;