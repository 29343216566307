import { useCallback, useEffect, useRef, useState } from "react";
import { Breadcrumb, BreadcrumbItem, Button, Container, Input, Label, Nav, NavLink, NavItem, TabContent, TabPane, Row, Col, Card, CardTitle, CardBody, ModalBody, ModalFooter, Modal, ModalHeader, InputGroup, InputGroupText } from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import styled, { css } from 'styled-components';
import update from 'immutability-helper';
import moment from 'moment';
import TextareaAutoSize from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheck, faTimes,  faChartSimple } from '@fortawesome/free-solid-svg-icons';

import './edit.scss';
import http from "../../AxoisClient";
import ReactQuill from 'react-quill';
import { quillFormats, quillModules } from "../../shared/quill.settings";
import 'react-quill/dist/quill.snow.css';


const emptyDay = { sessions: [] };
const emptySession = { title: '', duration: '', effort: 0, sections: [] };
const emptySection = { title: '', section: '', effort: 1, workout: '' };
const effortRegex = /@EFFORT\[([1-5])\]/;


const ProgramEdit = () => {
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const [modal, setModal] = useState(undefined);
    const [plans, setPlans] = useState([]);
    const [savingState, setSavingState] = useState(0);
    const importRef = useRef();
    const navigate = useNavigate();
    const [data, setData] = useState({
        title: '',
        coachNotes: '',
        planId: 0,
        public: false,
        publishAt: null,
        focus: '',
        year: moment().add(1, 'weeks').startOf('isoWeek').year(),
        week: moment().add(1, 'weeks').startOf('isoWeek').isoWeek(),
        days: [
            emptyDay,
            emptyDay,
            emptyDay,
            emptyDay,
            emptyDay,
            emptyDay,
            emptyDay
        ]
    })

    const days = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'];
    const fetchData = useCallback(async (canceled) => {
        if (canceled) {
            return; // ignore the result, as this `useEffect` was canceled
        }
        const data = await http.get('/api/admin/plans')
            .then(p => p.data);


        setPlans(data);

        if (id === 'new') {
            setData(d => update(d, { planId: { $set: data[0].id } }));
        }
        else {
            http.get(`/api/admin/workout/${id}`)
                .then(d => d.data)
                .then(setData);
        }
    }, [id]);



    useEffect(() => {
        let canceled = false;


        fetchData(canceled);
        return () => canceled = true;
    }, [id, fetchData]);


    const toggle = () => setModal(undefined);

    const onPasteWorkout = (e) => {
        var clipboardData, pastedData;

        // Stop data actually being pasted into div
        //e.stopPropagation();
        //e.preventDefault();

        // Get pasted data via clipboard API
        clipboardData = e.clipboardData || window.clipboardData;
        pastedData = clipboardData.getData('text/plain');

        parseData(pastedData);
    };

    const parseData = (importData) => {
        const newLine = navigator.platform.indexOf("Win") > -1 ? '\r\n' : '\n';

        // Parse paste
        if (importData.indexOf(newLine) > -1) {
            const lines = importData.split(newLine);

            let newData = data;
            let lastDay = -1;
            let lastSession = -1;
            let lastSection = -1;

            let sessions = [];

            for (var i in lines) {
                const line = lines[i];

                if (days.includes(line)) {

                    //Ny day
                    if (lastDay > -1 && sessions.length > 0) {
                        newData = update(newData, { days: { [lastDay]: { sessions: { $set: sessions } } } });
                        console.log('setData(update(data, { days: {[' + lastDay + ']: { sessions: {$set: [{\'title\': \'hej\'}]}}}}));')
                        console.log('Ny dag ' + lastDay + ' lägger till sessions ' + sessions.length);
                        sessions = [];
                        lastSession = -1;
                        lastSection = -1;
                    }
                    lastDay = days.indexOf(line);

                    console.log('Ny dag ' + lastDay);
                }
                // Parse session
                else if (line.toLowerCase().startsWith('SESSION -'.toLowerCase())) {
                    if (lastDay < 0) {
                        lastDay = modal;
                    }

                    lastSession = sessions.push({ title: line.substr(9)?.trim(), sections: [] });
                    lastSection = -1;
                }
                else if (/^[A-Za-z]\./.test(line)) {
                    if (lastSession > -1) {

                        // Fix unne

                        let section = line.substr(2);
                        let effort = 0;

                        const effortMatch = section.match(effortRegex)

                        if (effortMatch != null) {
                            section = section.replace(effortRegex, '');

                            effort = parseInt(effortMatch[1]-1);
                        }

                        lastSection = sessions[sessions.length - 1].sections.push({
                            section: line.substr(0, 1),
                            title: section.trim(),
                            effort: effort,
                            workout: ''
                        });
                    }
                }
                // else if (line === '') {
                //     if (lastSession > -1 && lastSection > -1) {
                //         sessions[lastSession - 1].sections[lastSection - 1].workout += '<br/>';
                //     }
                // }
                // else if (line.toLowerCase().startsWith('NOTIS:'.toLowerCase()) || line.toLowerCase().startsWith('NOTE'.toLowerCase())) {
                //     if (lastSession > -1 && lastSection > -1) {
                //         sessions[lastSession - 1].sections[lastSection - 1].workout += '<em>' + line + '</em>';
                //     }
                // }
                else {
                    if (lastSession > -1 && lastSection > -1) {
                        sessions[lastSession - 1].sections[lastSection - 1].workout += line + '\r\n';
                    }
                }
            }

            newData = update(newData, { days: { [lastDay]: { sessions: { $set: sessions } } } });

            newData.days.forEach((d) => {
                d.sessions.forEach(s => {
                    s.sections.forEach(v => {
                        v.workout = v.workout.trim();
                    });
                });
            });

            setData(newData);
            alert('Import klar!');
            setModal(-1);
        }
        else {
            alert('Ogiltig data');
        }
    }


    const addSession = (day) => {
        let session = { ...emptySession };
        setData(update(data, { days: { [day]: { sessions: { $push: [session] } } } }));
    };

    
    const addRestSession = (day) => {
        let session = { ...{ title: 'Vilodag', duration: '-',  effort: 0, sections: [ { title: 'Vila', section: 'A', effort: 0, workout: 'Vila är lika viktigt som träning. Låt din kropp vila ordentligt även att det lockar att köra en extra session. Passa på att kör lite extra mobility istället.' }] } };
     
        setData(update(data, { days: { [day]: { sessions: { $push: [session] } } } }));
    };

    const addSection = (day, session) => {
        const nextSection = String.fromCharCode((data.days[day].sessions[session].sections.at(-1)?.section?.at(-1)?.charCodeAt(0) || 64) + 1);
        let section = { ...emptySection, section: nextSection };
        setData(update(data, { days: { [day]: { sessions: { [session]: { sections: { $push: [section] } } } } } }));
    };

    const saveProgram = () => {

        var validated = true;
        let errors = "";
        setSavingState(1);
        for (var d = 0; d < data.days.length; d++) {
          
            const day = data.days[d];

            for (let i = 0; i < day.sessions.length; i++) {
                const session = day.sessions[i];
                const sections = session.sections;

                for (let j = 0; j < sections.length; j++) {
                    const section = sections[j];
                    const workout = section.workout;
                    const matches = workout.match(/@.+]/g);

                    if (matches?.length > 0) {

                        for (var e = 0; e < matches.length; e++) {
                            const match = matches[e];
                            if (!(/@([A-Z]+)\[(\d{1,3})(?:-(\d{1,3}))?\]/.test(match))) {
                                errors += `\n Sektion ${section.section}: ` + match;
                            }

                        }

                    }
                }
            }

            if (day.sessions?.length > 0 && day.sessions[0].duration?.length === undefined) {
                errors += `\nPasslängd måste fyllas i!`
                validated = false;
                setSavingState(3);
            }


         


      
        }


        if (data.focus?.length === 0) {
            errors += `\nFokus måste fyllas i!`;
            validated = false;
            setSavingState(3);
        }

        if (errors.length > 0) {
            window.alert(`Möjligt fel hittat på dag ${d + 1}: ${errors}`)
            validated = false;
            setSavingState(3);
        }

        if (!validated) return;

        data.title = `${plans[plans.findIndex(a => a.id === data.planId)]?.title} week ${data.week}`;
        http.post('/api/admin/workout', data)
            .then(e => {
                setSavingState(2);
                const nId = e.data.id;
                if (id === nId) {
                    fetchData(false);

                    setTimeout(() => setSavingState(0), 2000);
                }
                else {
                    navigate('/programs/' + nId);
                }
            })
            .catch(e => {
                alert(e)
                setSavingState(3);
            });
    }

    const deleteSection = (dayIndex, sessionIndex, sectionIndex, section) => {
        if (!window.confirm('Vill du ta bort sektionen?')) return;

        if (data.id === 0 || section.id === undefined) {
            setData(x => update(x, { days: { [dayIndex]: { sessions: { [sessionIndex]: { sections: { $splice: [[sectionIndex, 1]] } } } } } }));
        } else {
            http.delete(`/api/admin/workout/${data.id}/section/${section.id}`)
                .then(r => {
                    setData(x => update(x, { days: { [dayIndex]: { sessions: { [sessionIndex]: { sections: { $splice: [[sectionIndex, 1]] } } } } } }));
                })
                .catch(e => alert(e.messages));
        }
    };

    const deleteSession = (dayIndex, sessionIndex, session) => {
        if (!window.confirm('Vill du ta bort passet?')) return;

        if (data.id === 0 || session.id === undefined) {
            setData(x => update(x, { days: { [dayIndex]: { sessions: { $splice: [[sessionIndex, 1]] } } } }));
        } else {
            http.delete(`/api/admin/workout/${data.id}/session/${session.id}`)
                .then(r => {
                    setData(x => update(x, { days: { [dayIndex]: { sessions: { $splice: [[sessionIndex, 1]] } } } }));
                })
                .catch(e => alert(e.messages));
        }
    };

    const EffortSectionRingInput = styled.input`
        height: 30px;
        width: 30px;
        border-radius: 15px;
        color: #FFFFFF;
        text-align:center;
        border-width:0px;
        background-color: #536567;
        
        ${props => props.effort === 1 && css`
        background: #63b4f7;
        `}
        ${props => props.effort === 2 && css`
        background: #7cd992;
        `}
        ${props => props.effort === 3 && css`
        background: #ffa550;
        `}
        ${props => props.effort === 4 && css`
        background: #eb6060;
        `}
    `;

    return <Container>
        <Breadcrumb listTag="div">
            <BreadcrumbItem href="/" tag="a">Hem</BreadcrumbItem>
            <BreadcrumbItem href="/programs" tag="a">Program</BreadcrumbItem>
        </Breadcrumb>



        <div className="d-flex mb-4 pb-0">
            <h4 className="flex-fill">{id === 'new' ? 'Skapa program' : 'Redigera program'} - {plans[plans.findIndex(a => a.id === data.planId)]?.title} week {data.week}</h4>



            <Button onClick={() => setModal(0)} className="me-5">
                Import
            </Button>





            {/* <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="publish" checked={data.public} onChange={(e) => setData({ ...data, public: e.target.checked })} />
                <label class="form-check-label" for="publish">Published?</label>
            </div> */}




            <Button className="ms-3" onClick={saveProgram}>
                Spara
                {savingState === 1 && <FontAwesomeIcon icon={faSpinner} className="ms-2 text-white" spin="true" />}
                {savingState === 2 && <FontAwesomeIcon icon={faCheck} className="ms-2 text-white" />}
                {savingState === 3 && <FontAwesomeIcon icon={faTimes} className="ms-2 text-white" />}
            </Button>
        </div>
        <div className="d-flex flex-row align-items-center ">
            <div className='form-floating'>
                <Input type="select" className="form-control" name="planId" value={data.planId} onChange={(e) => setData({ ...data, planId: parseInt(e.target.value) })}>
                    {plans.length > 0 && plans.map((p) => <option key={p.id} value={p.id}>{p.title}</option>)}
                </Input>
                <Label htmlFor="title">Plan</Label>
            </div>
            <div className='form-floating mx-3'>
                <Input type="number" className="form-control" name="year"
                    value={data.year}
                    onChange={(e) => setData({ ...data, year: e.target.value })}></Input>
                <Label htmlFor="year">År</Label>
            </div>
            <div className='form-floating'>
                <Input type="number" className="form-control" name="week"
                    value={data.week}
                    onChange={(e) => setData({ ...data, week: e.target.value })}></Input>
                <Label htmlFor="week">Vecka</Label>
            </div>


            <div className='form-floating mx-3'>
                <Input type="datetime-local" className="form-control" name="publishAt"
                    value={data.publishAt}
                    onChange={(e) => setData({ ...data, publishAt: e.target.value })}></Input>
                <Label htmlFor="year">Publicera den</Label>
            </div>


        </div>

        <div className='form-box my-3'>
            <ReactQuill theme='snow'
                modules={quillModules}
                formats={quillFormats}
                value={data.coachNotes}
                onChange={(e) => setData(d => update(d, { coachNotes: { $set: e } }))} />

            <Label htmlFor="coachnotes">Tränarens kommentarer</Label>
        </div>

        <div className='form-floating my-3'>
            <Input name="focus" placeholder="Fokus" value={data.focus} onChange={e => setData(update(data, {  focus: { $set: e.target.value } }))}></Input>
                                                          
            <Label htmlFor="coachnotes">Veckans fokus</Label>
        </div>


        <div>
            <Nav tabs>
                {days.map((day, i) => (
                    <NavItem>
                        <NavLink
                            href="#"
                            className={activeTab === i ? 'active' : ''}
                            onClick={() => setActiveTab(i)}>
                            {day}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <TabContent activeTab={activeTab} >
                {days.map((day, dayIndex) => (
                    <TabPane tabId={dayIndex} className="border-1 p-2">
                        <Row>
                            <Col sm="12">
                                <div className="d-flex justify-content-between">

                                </div>
                                {data?.days[dayIndex]?.sessions?.length === 0 &&
                                    <div className="text-center m-5">
                                        <h6>Det finns inga pass</h6>
                                        <em>Lägg till det första nedan.</em>
                                    </div>
                                }
                                {data?.days[dayIndex]?.sessions?.map((session, sessionIndex) => (
                                    <Card key={sessionIndex} className="my-2  bg-light">
                                        <CardTitle className="p-3">
                                            <Row>
                                                <Col className="d-flex flex-row">
                                                    <InputGroup>
                                                        <InputGroupText>Pass {sessionIndex + 1} -</InputGroupText>
                                                        <Input value={session.title} placeholder="Titel" onChange={e => setData(update(data, { days: { [dayIndex]: { sessions: { [sessionIndex]: { title: { $set: e.target.value } } } } } }))}></Input>
                                                    </InputGroup>

                                                    {sessionIndex === 0 &&
                                                        <>
                                                            <Input name="duration" placeholder="Tidsåtgång (60-90min)" className="ms-2" value={session.duration} onChange={e => setData(update(data, { days: { [dayIndex]: { sessions: { [sessionIndex]: { duration: { $set: e.target.value } } } } } }))}></Input>
                                                            <Input type="select" value={session.effort} className="ms-2" onChange={(e) => setData(update(data, { days: { [dayIndex]: { sessions: { [sessionIndex]: { effort: { $set: parseInt(e.target.value) } } } } } }))}>
                                                                <option value={0} style={{ backgroundColor: 'red' }}>
                                                                    Zon 1
                                                                </option>
                                                                <option value={1}>
                                                                    Zon 2
                                                                </option>
                                                                <option value={2}>
                                                                    Zon 3
                                                                </option>
                                                                <option value={3}>
                                                                    Zon 4
                                                                </option>
                                                                <option value={4}>
                                                                    Zon 5
                                                                </option>
                                                            </Input>
                                                        </>
                                                    }
                                                </Col>

                                                <Col xs="1" className="d-flex justify-content-end">
                                                    <div>
                                                        <Button color="none" onClick={() => deleteSession(dayIndex, sessionIndex, session)}>x</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardTitle>
                                        <CardBody>
                                            {session.sections.length === 0 &&
                                                <div className="text-center m-5">
                                                    <h6>Det finns inga sektioner</h6>
                                                    <em>Lägg till det första nedan.</em>
                                                </div>
                                            }
                                            {session.sections.map((section, sectionIndex) => (
                                                <Card key={sectionIndex} className="my-3">
                                                    <CardTitle>
                                                        <div className="d-flex flex-row align-items-center m-2">
                                                            <EffortSectionRingInput className="justify-self-start me-1" maxLength="1" value={section.section} effort={section.effort} onChange={(e) => setData(update(data, { days: { [dayIndex]: { sessions: { [sessionIndex]: { sections: { [sectionIndex]: { section: { $set: e.target.value } } } } } } } }))} ></EffortSectionRingInput>
                                                            <Input style={{ "maxWidth": '100px' }} type="select" value={section.effort} onChange={(e) => setData(update(data, { days: { [dayIndex]: { sessions: { [sessionIndex]: { sections: { [sectionIndex]: { effort: { $set: parseInt(e.target.value) } } } } } } } }))}>
                                                                <option value={0} >
                                                                    Zon 1
                                                                </option>
                                                                <option value={1} className="effort-1">
                                                                    Zon 2
                                                                </option>
                                                                <option value={2} className="effort-2">
                                                                    Zon 3
                                                                </option>
                                                                <option value={3} className="effort-3">
                                                                    Zon 4
                                                                </option>
                                                                <option value={4} className="effort-4">
                                                                    Zon 5
                                                                </option>
                                                            </Input>
                                                            <span className="mx-3 justify-self-start">-</span>
                                                            <Input className="w-25 justify-self-start"
                                                                placeholder="Titel"
                                                                value={section.title}
                                                                onChange={(e) => setData(update(data, { days: { [dayIndex]: { sessions: { [sessionIndex]: { sections: { [sectionIndex]: { title: { $set: e.target.value } } } } } } } }))}
                                                            />
                                                            <div className=" ms-auto d-flex gap-1">
                                                            <label>Gratis?</label>
                                                            <Input type="select"    value={section.isFree ? 1 : 0}
                                                                onChange={(e) => setData(update(data, { days: { [dayIndex]: { sessions: { [sessionIndex]: { sections: { [sectionIndex]: { isFree: { $set: parseInt(e.target.value) === 1 } } } } } } } }))}
                                                            >
                                                            <option value={0} >
                                                                    Nej
                                                                </option>
                                                                <option value={1}>
                                                                    Ja
                                                                </option>
                                                            </Input>
                                                          
                                                            <button onClick={() => deleteSection(dayIndex, sessionIndex, sectionIndex, section)} className="btn btn-sm">x</button>
                                                            </div>
                                                        </div>
                                                    </CardTitle>
                                                    <CardBody>
                                                        <TextareaAutoSize minRows={5}
                                                            className="form-control"
                                                            value={section.workout}
                                                            onChange={(e) => setData(update(data, { days: { [dayIndex]: { sessions: { [sessionIndex]: { sections: { [sectionIndex]: { workout: { $set: e.target.value } } } } } } } }))} />

                                                            <div className="d-flex flex-row align-items-center m-2">
                                                            <FontAwesomeIcon icon={faChartSimple}  title="Leaderboard" />
                                                            <Input className="w-25  m-2" type="select" value={section.resultType1} onChange={(e) => setData(update(data, { days: { [dayIndex]: { sessions: { [sessionIndex]: { sections: { [sectionIndex]: { resultType1: { $set: parseInt(e.target.value) } } } } } } } }))}>
                                                                <option value={0}>
                                                                    Ingen leaderboard
                                                                </option>
                                                                <option value={1}>
                                                                    Vikt - Lägst vinner
                                                                </option>
                                                                <option value={2}>
                                                                    Tid - Lägst vinner
                                                                </option>
                                                                <option value={4}>
                                                                    Rounds - Lägst vinner
                                                                </option>
                                                                <option value={3}>
                                                                    Reps - Lägst vinner
                                                                </option>
                                                                <option value={5}>
                                                                    Meter - Lägst vinner
                                                                </option>
                                                                <option value={6}>
                                                                    Kalorier - Lägst vinner
                                                                </option>
                                                                <option value={10}>
                                                                    Vikt - Högst vinner
                                                                </option>
                                                                <option value={20}>
                                                                    Tid - Högst vinner
                                                                </option>
                                                                <option value={40}>
                                                                    Rounds - Högst vinner
                                                                </option>
                                                                <option value={30}>
                                                                    Reps - Högst vinner
                                                                </option>
                                                                <option value={50}>
                                                                    Meter - Högst vinner
                                                                </option>
                                                                <option value={60}>
                                                                    Kalorier - Högst vinner
                                                                </option>
                                                            </Input>
                                                        
                                                            <Input className="w-25 m-2"  type="select" disabled={section.resultType1 === 0 } value={section.resultType2} onChange={(e) => setData(update(data, { days: { [dayIndex]: { sessions: { [sessionIndex]: { sections: { [sectionIndex]: { resultType2: { $set: parseInt(e.target.value) } } } } } } } }))}>
                                                                <option value={0}>
                                                                    Typ 2
                                                                </option>
                                                                <option value={1}>
                                                                    Vikt - Lägst vinner
                                                                </option>
                                                                <option value={2}>
                                                                    Tid - Lägst vinner
                                                                </option>
                                                                <option value={4}>
                                                                    Rounds - Lägst vinner
                                                                </option>
                                                                <option value={3}>
                                                                    Reps - Lägst vinner
                                                                </option>
                                                                <option value={5}>
                                                                    Meter - Lägst vinner
                                                                </option>
                                                                <option value={6}>
                                                                    Kalorier - Lägst vinner
                                                                </option>
                                                                <option value={10}>
                                                                    Vikt - Högst vinner
                                                                </option>
                                                                <option value={20}>
                                                                    Tid - Högst vinner
                                                                </option>
                                                                <option value={40}>
                                                                    Rounds - Högst vinner
                                                                </option>
                                                                <option value={30}>
                                                                    Reps - Högst vinner
                                                                </option>
                                                                <option value={50}>
                                                                    Meter - Högst vinner
                                                                </option>
                                                                <option value={60}>
                                                                    Kalorier - Högst vinner
                                                                </option>
                                                            </Input>
                                                            <label>Antal varv:</label>
                                                            <Input className="w-25  m-2" type="number" disabled={section.resultType1 === 0 } placeholder="Antal resultat" value={section.resultRounds} onChange={(e) => setData(update(data, { days: { [dayIndex]: { sessions: { [sessionIndex]: { sections: { [sectionIndex]: { resultRounds: { $set: parseInt(e.target.value) } } } } } } } }))} />
                                                                
                                                       
                                                            </div>
                                                    </CardBody>
                                                </Card>
                                            ))}

                                            <div className="d-flex flex-column text-center justify-content-center">
                                                {!data?.days[dayIndex]?.sessions[sessionIndex].sections &&
                                                    <p><em>Det finns inga sessions ännu</em></p>
                                                }
                                                <Button className="mx-auto" onClick={() => addSection(dayIndex, sessionIndex)}>Lägg till sektion</Button>
                                            </div>
                                        </CardBody>

                                    </Card>
                                ))}

                                <div className="d-flex flex-column text-center justify-content-center">
                                    {!data?.days[dayIndex]?.sessions &&

                                        <p><em>Det finns inga sessions ännu</em></p>


                                    } 
                                    <div className="d-flex flex-row text-center justify-content-center">
                                        <Button className="me-2" onClick={() => addSession(dayIndex)}>Lägg till session</Button>
                                        <Button className="ms-2"  onClick={() => addRestSession(dayIndex)}>Lägg till vilodag</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                ))}
            </TabContent>
        </div>
        <Modal isOpen={modal > -1} toggle={toggle}>
            <ModalHeader toggle={toggle}>Import workout</ModalHeader>
            <ModalBody>
                <Input ref={importRef} type="textarea" style={{ 'minHeight': '200px' }} placeholder="Klistra in programmet i rutan för att importera" onPaste={(e) => onPasteWorkout(e)} />
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => parseData(importRef.current?.value)}>Importera</Button>
            </ModalFooter>
        </Modal>
    </Container>;
}

export default ProgramEdit;