import {useEffect, useRef, useState} from "react";
import http from "../AxoisClient";
import './ImageUploader.scss';

const ImageUploader = (props) => {
    const [selectedFile, setSelectedFile] = useState();
    const [src, setSrc] = useState(props.src);
    const fileInput = useRef();

    // On file upload (click the upload button)
    const onFileUpload = event => {
        setSelectedFile(event.target.files[0]);

    };

    useEffect(() => {
        setSrc(props.src);
        if (selectedFile) {
            // Create an object of formData
            const formData = new FormData();

            // Update the formData object
            formData.append(
                "file",
                selectedFile,
                selectedFile.name
            );

            // Details of the uploaded file
            console.log(selectedFile);

            // Request made to the backend api
            // Send formData object
            http.post("api/admin/images/upload", formData)
                .then(res => res.data)
                .then(s => {
                    setSrc(s.uri)
                    props.onChange(s.uri);
                    setSelectedFile(undefined);
                })
                .catch(e => alert(e.messages));
        }
    }, [selectedFile, props])
    const renderImage = () => {
        if (src) {
            return <img style={{'objectFit': 'contain'}} src={src} alt="header"/>;
        } else {
            return <h6>Klicka här för att välja fil</h6>;

        }
    };

    return (
        <div className="image-uploader" {...props}>
            <div className="image-wrapper" onClick={() => fileInput.current.click()}>
                {renderImage()}
            </div>
            <input ref={fileInput} type="file" onChange={onFileUpload}/>
        </div>);


};
export default ImageUploader;